import type { RouteRecordRaw } from "vue-router"

export const useNavigationUtils = () => {
  const { $R } = useNuxtApp()

  const getNavigationRoutes = () => {
    const router = useRouter()

    const rawRoutes = router.options.routes as RouteRecordRaw[]
    const cleanedRoutes = _cleanRoutes(rawRoutes)

    return {
      root: _getRootRoute(cleanedRoutes),
      others: _getOtherRoutes(cleanedRoutes)
    }
  }

  const getIcon = (route: RouteRecordRaw) => route.meta?.navigationIcon

  const getTitle = (route: RouteRecordRaw) => route.meta?.navigationLabel

  const _cleanRoutes = (routes: RouteRecordRaw[]) => {
    const possible = _getPossibleRoutes(routes)
    const sorted = _sortRoutesByName(possible)

    return sorted.map(
      route => {
        let children = _getChildrenRoutes(route)

        if (children.length) {
          children = _cleanRoutes(children)
        }

        route.children = children

        return route
      }
    )
  }

  const _getPossibleRoutes = (routes: RouteRecordRaw[]) => routes.filter(
    route =>
      route.meta?.navigationLabel
      && route.meta?.navigationIcon
  )

  const _sortRoutesByName = (routes: RouteRecordRaw[]) => routes.sort(
    (a, b) => (a.name as any) - (b.name as any)
  )

  const _getChildrenRoutes = (route: RouteRecordRaw) => {
    let children = route.children ?? []

    const childrenNames = children.map(c => c.name)
    const hasSimilarlyNamedChild = $R.includes(route.name, childrenNames)

    // If the route is named to match any of its children, it's usually because the router view has a separate
    // navigation element providing access to the child views -> the parent view shouldn't expose the children
    // in the main navigation tree.
    // Note that by default in cases like this:
    //
    // -| parent/
    // ---| index.vue
    // ---| named.vue
    // -| parent.vue
    //
    // the route name of `parent.vue` is `undefined`, and it has to be manually named to match the route name of
    // `parent/index.vue` for this to work. Not clear whether this is by design, or a bug in vue-router.
    if (hasSimilarlyNamedChild) {
      return []
    }

    return children
  }

  const _getRootRoute = (routes: RouteRecordRaw[]) =>
    routes.find(route => route.name === 'index')

  const _getOtherRoutes = (routes: RouteRecordRaw[]) =>
    routes.filter(route => route.name !== 'index')

  return {
    getNavigationRoutes,
    getIcon,
    getTitle
  }
}
